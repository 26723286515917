import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import Menu from "@oriola-origo/core/lib/Menu";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import ListItemText from "@oriola-origo/core/lib/ListItemText";
import ListItemIcon from "@oriola-origo/core/lib/ListItemIcon";
import { useTranslation } from "react-i18next";
import { Progress, Button } from "@oriola-origo/origo-ui-core";
import { useSelector } from "react-redux";
import {
  PrinterSmallIcon,
  ChangeOueueIcon,
  EditCaseIcon,
  CopyCaseIcon,
} from "../../../images";
import { CaseType } from "../../../constants/caseType";
import { CaseStatus } from "../../../constants/caseStatus";
import CaseItemsType from "../../../constants/caseItemsType";
import { isOriolaUser, isCustomerEditRole } from "../../auth/permission";
import printButtonsEnabled from "./printButtonsEnabled";
import useWindowResize from "../../../hooks/useWindowResize";

function CaseViewButtons({
  reclamationCase,
  fetchingPdfUrl,
  user,
  onQueueButtonClick,
  onEditCaseButtonClick,
  onCopyCaseButtonClick,
  onPrintButtonClick,
  onChangeSubStatusClick,
  onPrintCaseDetailsClick,
  forceMode,
}) {
  const { t } = useTranslation();
  const { items } = reclamationCase;
  const { CaseCreationConfig } = useSelector(state => state.fieldsConfig);
  const { SPD_CASE_ENABLED } = CaseCreationConfig || {};
  const baseRef = useRef(null);
  const toolbarRef = useRef(null);
  const [menuMode, setMenuMode] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const TOOLBAR_DEFAULT_WIDTH = 850;
  const currentUserIsOriolaUser = isOriolaUser(user);
  const { returnDocumentButtonEnabled, printCaseDetailsButtonEnabled } =
    printButtonsEnabled(reclamationCase, user);
  const isCustomerEditingSolvedCase =
    isCustomerEditRole(user) &&
    reclamationCase.caseStatus === CaseStatus.SOLVED;

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasChangeStatusAction = () => !!currentUserIsOriolaUser;

  const hasChangeQueueAction = () => {
    if (!currentUserIsOriolaUser) {
      return false;
    }
    //  Cannot edit solved SPD cases
    return !(
      reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
      reclamationCase.caseStatus !== CaseStatus.OPEN_SUBMITTED
    );
  };
  const hasEditAction = () => {
    if (!currentUserIsOriolaUser && !isCustomerEditRole(user)) {
      return false;
    }
    // Customers should not edit solved cases
    if (isCustomerEditingSolvedCase) {
      return false;
    }
    // Existing logic - Cannot edit solved SPD cases
    return !(
      reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
      reclamationCase.caseStatus !== CaseStatus.OPEN_SUBMITTED
    );
  };

  const hasPrintCaseDetailsAction = () => printCaseDetailsButtonEnabled;

  const hasCopyCaseAction = () => {
    if (SPD_CASE_ENABLED !== true) {
      return false;
    }
    if (!currentUserIsOriolaUser) {
      return false;
    }
    const productCount = items.filter(
      x => x.type === CaseItemsType.PRODUCT_ORDER
    ).length;
    return productCount !== 0;
  };

  const hasPrintReturnDocumentAction = () => returnDocumentButtonEnabled;

  const countButtons = () => {
    let count = 0;
    if (hasChangeQueueAction()) {
      count += 1;
    }
    if (hasEditAction()) {
      count += 1;
    }
    if (hasPrintReturnDocumentAction()) {
      count += 1;
    }
    if (hasPrintCaseDetailsAction()) {
      count += 1;
    }
    if (hasCopyCaseAction()) {
      count += 1;
    }
    if (hasChangeStatusAction()) {
      count += 1;
    }
    return count;
  };

  const getToolbarEstimatedWidth = () => {
    const buttons = countButtons();
    if (buttons >= 1) {
      return buttons * 145;
    }
    return TOOLBAR_DEFAULT_WIDTH;
  };

  useWindowResize(() => {
    const available =
      (baseRef.current?.parentElement?.clientWidth ?? TOOLBAR_DEFAULT_WIDTH) -
      64;
    if (!menuMode && toolbarRef.current?.clientWidth > available) {
      setMenuMode(true);
    } else if (
      menuMode &&
      baseRef.current?.clientWidth > getToolbarEstimatedWidth()
    ) {
      setMenuMode(false);
      handleClose();
    }
  });

  useEffect(() => {
    const available =
      (baseRef.current?.parentElement?.clientWidth ?? TOOLBAR_DEFAULT_WIDTH) -
      64;
    if (toolbarRef.current?.clientWidth > available) {
      setMenuMode(true);
    } else {
      setMenuMode(false);
    }
  }, []);

  const { editingCopiedCase } = useSelector(state => state.case);
  if (editingCopiedCase) {
    return null;
  }

  const renderSetSubStatusButton = () => {
    if (!hasChangeStatusAction()) {
      return false;
    }
    return (
      <Button
        id="set-substatus-button"
        variant="transparent"
        onClick={onChangeSubStatusClick}
      >
        <Box display="flex" alignItems="center">
          <ChangeOueueIcon />
          <Box ml={1}>
            <Typography variant="body1">
              {t("changeSubStatus.changeStatus")}
            </Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderQueueChangeButton = () => {
    if (!hasChangeQueueAction()) {
      return null;
    }

    return (
      <Button
        id="queue-button"
        variant="transparent"
        onClick={onQueueButtonClick}
      >
        <Box display="flex" alignItems="center">
          <ChangeOueueIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("changeQueue.title")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderEditButtons = () => {
    if (!hasEditAction()) {
      return null;
    }

    return (
      <Button
        id="edit-case"
        variant="transparent"
        onClick={onEditCaseButtonClick}
      >
        <Box display="flex" alignItems="center">
          <EditCaseIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("editCase.editCase")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderReturnDocumentButton = () => {
    if (!hasPrintReturnDocumentAction()) {
      return null;
    }

    return (
      <Button
        id="print-button"
        variant="transparent"
        onClick={onPrintButtonClick}
        disabled={fetchingPdfUrl === true}
      >
        <Box display="flex" alignItems="center">
          {fetchingPdfUrl !== true && <PrinterSmallIcon />}
          <Progress size={24} show={fetchingPdfUrl || false} />
          <Box ml={1}>
            <Typography variant="body1">{t("printDocument")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderPrintCaseDetailsButton = () => {
    if (!hasPrintCaseDetailsAction()) {
      return null;
    }
    return (
      <Button
        id="print-case-details"
        data-testid="print-case-details"
        variant="transparent"
        onClick={() => {
          onPrintCaseDetailsClick();
        }}
      >
        <Box display="flex" alignItems="center">
          <PrinterSmallIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("printCaseDetails")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderCopyCaseButton = () => {
    if (!hasCopyCaseAction()) {
      return null;
    }
    return (
      <Button
        id="copy-case-button"
        variant="transparent"
        onClick={onCopyCaseButtonClick}
      >
        <Box display="flex" alignItems="center">
          <CopyCaseIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("copyCase.copyCase")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const getMenuItem = button => {
    if (button) {
      return (
        <MenuItem
          data-testid={button.testid}
          key={button.text}
          onClick={() => {
            button.onClick();
            handleClose();
          }}
        >
          <Box display="flex" sx={{ alignItems: "center" }}>
            <ListItemIcon>
              <FontIcon color="primary">{button.icon}</FontIcon>
            </ListItemIcon>
            <ListItemText>{button.text}</ListItemText>
          </Box>
        </MenuItem>
      );
    }
    return null;
  };

  const renderMenuChangeQueue = () =>
    hasChangeQueueAction() &&
    getMenuItem({
      testid: "change-queue",
      text: t("changeQueue.title"),
      onClick: onQueueButtonClick,
      icon: "swap_vert",
    });

  const renderMenuPrintCaseDetails = () =>
    hasPrintCaseDetailsAction() &&
    getMenuItem({
      testid: "print-case-details",
      text: t("printCaseDetails"),
      onClick: onPrintCaseDetailsClick,
      icon: "print",
    });

  const renderMenuPrintReturnDocument = () =>
    hasPrintReturnDocumentAction() &&
    getMenuItem({
      testid: "print-document",
      text: t("printDocument"),
      onClick: onPrintButtonClick,
      icon: "print",
    });

  const renderMenuCopyCase = () =>
    hasCopyCaseAction() &&
    getMenuItem({
      testid: "copy-case",
      text: t("copyCase.copyCase"),
      onClick: onCopyCaseButtonClick,
      icon: "content_copy",
    });

  const renderMenuEditCase = () =>
    hasEditAction() &&
    getMenuItem({
      testid: "edit-case",
      text: t("editCase.editCase"),
      onClick: onEditCaseButtonClick,
      icon: "edit",
    });

  const renderMenuChangeStatus = () =>
    hasChangeStatusAction() &&
    getMenuItem({
      testid: "change-status",
      text: t("changeSubStatus.changeStatus"),
      onClick: onChangeSubStatusClick,
      icon: "swap_vert",
    });

  const renderMenuOptions = () => (
    <div>
      {renderMenuChangeStatus()}
      {renderMenuCopyCase()}
      {renderMenuChangeQueue()}
      {renderMenuEditCase()}
      {renderMenuPrintReturnDocument()}
      {renderMenuPrintCaseDetails()}
    </div>
  );

  const displayMenu = () => (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        data-testid="action-menu"
        size="large"
        onClick={handleMenuClick}
        aria-label={t("actionMenu")}
      >
        <FontIcon>more_vert</FontIcon>
      </IconButton>
      <Menu
        aria-label="Header menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {renderMenuOptions()}
      </Menu>
    </Box>
  );

  const displayToolbar = () => (
    <Box ref={toolbarRef} display="flex" justifyContent="flex-end" mb={3}>
      {renderSetSubStatusButton()}
      {renderCopyCaseButton()}
      {renderQueueChangeButton()}
      {renderEditButtons()}
      {renderReturnDocumentButton()}
      {renderPrintCaseDetailsButton()}
    </Box>
  );

  const getDisplayMode = () => {
    if (forceMode === 1) {
      return displayMenu();
    }
    if (forceMode === 2) {
      return displayToolbar();
    }
    return menuMode ? displayMenu() : displayToolbar();
  };

  return (
    <OriolaThemeProvider>
      <div
        ref={baseRef}
        style={{
          display: "flex",
          overflow: "hidden",
          justifyContent: "flex-end",
        }}
        className="no-print"
      >
        {getDisplayMode()}
      </div>
    </OriolaThemeProvider>
  );
}

CaseViewButtons.propTypes = {
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    caseStatus: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  fetchingPdfUrl: PropTypes.bool,
  user: PropTypes.shape({}),
  history: PropTypes.shape({}),
  onQueueButtonClick: PropTypes.func,
  onEditCaseButtonClick: PropTypes.func,
  onPrintButtonClick: PropTypes.func,
  onCopyCaseButtonClick: PropTypes.func,
  onChangeSubStatusClick: PropTypes.func,
  onPrintCaseDetailsClick: PropTypes.func,
  forceMode: PropTypes.number,
};

CaseViewButtons.defaultProps = {
  reclamationCase: {},
  fetchingPdfUrl: false,
  user: {},
  history: {},
  onQueueButtonClick: null,
  onEditCaseButtonClick: null,
  onPrintButtonClick: null,
  onCopyCaseButtonClick: null,
  onChangeSubStatusClick: null,
  onPrintCaseDetailsClick: null,
  forceMode: 0,
};

export default CaseViewButtons;
