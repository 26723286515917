import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Progress } from "@oriola-origo/origo-ui-core";
import { Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  fetchCase,
  clearValidationErrors,
  fetchMessages,
  initializeCaseHandlingItems,
  setLocalHandlingData,
  fetchCustomerById,
  updateContactInfo,
  startToCopyCase,
  getRelatedCases,
  clearlocalHandlingData,
} from "../../redux";
import {
  isAllowed,
  Permission,
  isOriolaUser,
  isCustomer,
  isPharmaceuticalCompany,
} from "../auth/permission";
import NotFound from "../404/404";
import CompensationHandling from "./compensationHandling/compensationHandling";
import { Can, ANY_CUSTOMER } from "../auth";
import { getCaseHandlingData, isSpdCase } from "../../utils/case/case";
import CaseHandlerUpdater from "./caseHandlerUpdater/caseHandlerUpdater";
import CaseHeaderWarnings from "./caseHeaderWarnings/caseHeaderWarnings";
// eslint-disable-next-line import/no-cycle
import CaseViewContent from "./caseViewContent/caseViewContent";
import CopyCaseView from "./copyCaseView/copyCaseView";
import { transformCustomer } from "../../utils/customer/customer";

export const CASE_VIEW_MAX_WIDTH_PX = 1920;
export const CASE_VIEW_WIDTH_PERCENTAGE = "95%";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
  },
  compensation: {
    width: "100%",
  },
  copyCase: {
    padding: theme.spacing(3),
    flexBasis: 800,
    flexShrink: 0,
    flexGrow: 0,
    width: "100%",
    overflowY: "auto",
    marginLeft: "5px",
  },
  operationButtons: {
    "& > button:first-child": {
      marginLeft: "auto",
    },
  },
}));

const getCaseState = (state, reclamationId) => {
  if (
    state.case &&
    state.case.case &&
    (state.case.case.reclamationId === reclamationId ||
      state.case.case.sourceReclamationId === reclamationId)
  ) {
    return state.case;
  }
  // reclamationId does not match case in state
  return {
    ...state.case,
    case: null,
  };
};

function CaseView({ match, history }) {
  // Height of app header and paddings
  const APP_HEADING_HEIGHT = 101;
  const dispatch = useDispatch();
  const classes = useStyles();
  const containerElementRef = useRef();
  // Position where content scrollable area begins
  const [top, setTop] = useState(APP_HEADING_HEIGHT);
  const { reclamationId } = match.params;
  const {
    case: activeCase,
    sourceCase,
    sendingCase,
    fetching,
    fetchingPdfUrl,
    editingCopiedCase,
  } = useSelector(state => getCaseState(state, reclamationId));
  const fieldsConfig = useSelector(state => state.fieldsConfig);

  const reclamationCase = editingCopiedCase ? sourceCase : activeCase;

  const { userData } = useSelector(state => state.user);
  const [isAllowedToEditHandlingData, setIsAllowedToEditHandlingData] =
    useState(true);
  const showProgress = fetching || sendingCase || false;
  const [isError, setIsError] = useState(false);
  const [copiedCaseTypeSelected, setCopiedCaseTypeSelected] = useState(false);
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const currentUserIsCustomer = isCustomer(userData);
  const currentUserIsPrincipal = isPharmaceuticalCompany(userData);
  const [currentCaseHandler, setCurrentCaseHandler] = useState(null);

  useEffect(() => {
    const caseFetchedCallback = fetchedCase => {
      // fetch messages. If non SPD for oriola and customer, if SPD for all
      if (
        currentUserIsOriolaUser ||
        currentUserIsCustomer ||
        isSpdCase(fetchedCase)
      ) {
        dispatch(fetchMessages(reclamationId));
      }

      dispatch(initializeCaseHandlingItems(fetchedCase.items));

      const caseHandlingData = getCaseHandlingData(fetchedCase) || {};
      dispatch(setLocalHandlingData(caseHandlingData));

      if (fetchedCase.customerId && currentUserIsOriolaUser) {
        dispatch(fetchCustomerById(fetchedCase.customerId)).then(customer => {
          dispatch(
            updateContactInfo({ customer: transformCustomer(customer) })
          );
        });
      }

      if (fetchedCase.sourceReclamationId) {
        // If case SPD and principal, do not fetch source case as principals have no access to non-SPD cases
        if (!(currentUserIsPrincipal && isSpdCase(fetchedCase))) {
          dispatch(getRelatedCases(fetchedCase));
        }
      }
    };

    if (reclamationId) {
      dispatch(fetchCase(reclamationId)).then(fetchedCase => {
        if (fetchedCase) {
          caseFetchedCallback(fetchedCase);
        }
        setIsError(fetchedCase == null);
      });
      dispatch(clearValidationErrors());
      dispatch(clearlocalHandlingData());
    }
  }, [
    currentUserIsCustomer,
    currentUserIsOriolaUser,
    currentUserIsPrincipal,
    dispatch,
    reclamationId,
  ]);

  useEffect(() => {
    if (userData) {
      // set user rights
      setIsAllowedToEditHandlingData(
        isAllowed(userData, Permission.HANDLING_DATA_UPDATE, ANY_CUSTOMER)
      );
    }
  }, [userData, reclamationCase]);

  const renderProgress = () => (
    <Box p={3}>
      <Progress show />
    </Box>
  );

  // Calculate the height for content scrollable area
  // The top position depends on possible updater/warning banners/messages
  const getPanelHeight = () => {
    if (containerElementRef?.current) {
      const refTop =
        containerElementRef?.current?.getBoundingClientRect()?.top || 0;
      if (refTop > APP_HEADING_HEIGHT && refTop !== top) {
        setTop(refTop);
        // Count the available height dynamically - there may be banners that affect the height
        // 64px comes from paddings (32 + 32)
        // 69px is the height of the app header
        return `calc(100vh - ${refTop + 32}px)`;
      }
    }
    return `calc(100vh - ${top + 32}px)`;
  };

  const renderCasePage = () => (
    <Box>
      {currentUserIsOriolaUser && (
        <CaseHandlerUpdater
          reclamationCase={reclamationCase}
          onCaseHandlerUpdated={handler => setCurrentCaseHandler(handler)}
        />
      )}
      {isAllowedToEditHandlingData === true && (
        <CaseHeaderWarnings
          reclamationCase={reclamationCase}
          caseHandler={currentCaseHandler}
          userData={userData}
          fieldsConfig={fieldsConfig}
        />
      )}
      <Box
        display="flex"
        justifyContent="center"
        height={getPanelHeight()}
        style={{ overflow: "hidden" }}
      >
        <Box
          ref={containerElementRef}
          display="flex"
          style={{ overflow: "auto", marginRight: 0, width: "100%" }}
        >
          <CaseViewContent
            floatingHeaderContainerElement={containerElementRef}
            reclamationCase={reclamationCase}
            userData={userData}
            currentCaseHandler={currentCaseHandler}
            fetchingPdfUrl={fetchingPdfUrl}
            history={history}
            isAllowedToEditHandlingData={isAllowedToEditHandlingData}
            onCopyCaseButtonClick={() => {
              dispatch(startToCopyCase());
            }}
            fieldsConfig={fieldsConfig}
          />
        </Box>
        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_UPDATE}
          customerContext={ANY_CUSTOMER}
        >
          {editingCopiedCase ? (
            <Paper className={`${classes.copyCase} no-print`}>
              <CopyCaseView
                currentUserIsOriolaUser={currentUserIsOriolaUser}
                copiedCaseTypeSelected={copiedCaseTypeSelected}
                setCopiedCaseTypeSelected={setCopiedCaseTypeSelected}
                reclamationCase={activeCase}
              />
            </Paper>
          ) : (
            <Box
              display="flex"
              style={{
                overflow: "hidden",
                marginLeft: "5px",
                flexBasis: 600,
                flexShrink: 0,
                flexGrow: 0,
                width: "100%",
              }}
            >
              <Paper className={`${classes.compensation} no-print`}>
                <CompensationHandling
                  currentUserIsOriolaUser={currentUserIsOriolaUser}
                />{" "}
              </Paper>
            </Box>
          )}
        </Can>
      </Box>
    </Box>
  );

  if (isError) {
    return <NotFound />;
  }

  return (
    <div className={classes.container}>
      <Box
        width={CASE_VIEW_WIDTH_PERCENTAGE}
        margin="auto"
        id="case-view"
        maxWidth={`${CASE_VIEW_MAX_WIDTH_PX}px`}
      >
        {showProgress || reclamationCase == null
          ? renderProgress()
          : renderCasePage()}
      </Box>
    </div>
  );
}

CaseView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      reclamationId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({}),
};

CaseView.defaultProps = {
  match: { params: undefined },
  history: undefined,
};

export default CaseView;
