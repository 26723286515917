import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import { useDispatch, useSelector } from "react-redux";
import { RadioButtonSelection, TitleInput } from "../../common";
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import DateAndEditor from "../dateAndEditor";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import Title from "../title/title";
import ResetButton from "../../common/resetButton/resetButton";

const useStyles = makeStyles(() => ({
  titleAndResetButton: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function CompensationInstructions({
  user,
  disabled,
  currentUserIsPharmaceuticalCompanyEditRole,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const {
    spdCustomerCompensationReasonRejectionError,
    spdCustomerCompensationPermittedError,
  } = useSelector(state => state.validationErrors);
  const { compensationInstructions = {}, modifications = {} } =
    localHandlingData;
  const { isCompensationToCustomerPermitted, reasonForRejection = "" } =
    compensationInstructions;
  const compensationInstructionsText =
    compensationInstructions.compensationInstructions || "";

  const getIsCompensationPermittedToCustomerValue = () => {
    if (isCompensationToCustomerPermitted == null) {
      return "";
    }
    return isCompensationToCustomerPermitted === true ? "true" : "false";
  };

  const setIsCompensationPermittedToCustomerValue = updatedValue => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdCustomerCompensationPermittedError: null })
    );

    const booleanValue = updatedValue === "true";

    dispatch(
      updateLocalHandlingDataField(
        booleanValue,
        "compensationInstructions",
        "isCompensationToCustomerPermitted"
      )
    );
  };

  const resetIsCompensationPermittedToCustomerValue = () => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdCustomerCompensationPermittedError: null })
    );

    dispatch(
      updateLocalHandlingDataField(
        null,
        "compensationInstructions",
        "isCompensationToCustomerPermitted"
      )
    );
  };

  const setReasonForRejection = updatedValue => {
    // clear validation errors
    dispatch(
      updateValidationError({
        spdCustomerCompensationReasonRejectionError: null,
      })
    );

    dispatch(
      updateLocalHandlingDataField(
        updatedValue,
        "compensationInstructions",
        "reasonForRejection"
      )
    );
  };

  const setCompensationInstructionsText = updatedValue => {
    dispatch(
      updateLocalHandlingDataField(
        updatedValue,
        "compensationInstructions",
        "compensationInstructions"
      )
    );
  };

  const getModificationData = field =>
    getSpdModificationData(modifications, `compensationInstructions.${field}`);
  const hasModificationData = field => !!getModificationData(field);

  const compensationPermittedToCustomerValue =
    getIsCompensationPermittedToCustomerValue();

  const modificationData = getModificationData(
    "isCompensationToCustomerPermitted"
  );
  const displayInfoText = (controlValue, activeValue, modificationInfo) =>
    controlValue === activeValue ||
    (controlValue === "" && modificationInfo?.isLocalChange);

  return (
    <Box
      id="compensation-instructions"
      className="handling-section"
      {...containerProps}
    >
      <div className={classes.titleAndResetButton}>
        <Title title={t("compensationInstructions.title")} />
        {currentUserIsPharmaceuticalCompanyEditRole && (
          <ResetButton
            onClick={resetIsCompensationPermittedToCustomerValue}
            disabled={!compensationPermittedToCustomerValue}
          />
        )}
      </div>
      <RadioButtonSelection
        mt={3}
        disabled={disabled}
        value={compensationPermittedToCustomerValue}
        button1Value="true"
        button2Value="false"
        onChecked={setIsCompensationPermittedToCustomerValue}
        title={t("compensationInstructions.compensatedTitle")}
        button1LabelText={t(
          "compensationInstructions.compensatedToCustomerPermitted"
        )}
        button2LabelText={t(
          "compensationInstructions.compensatedToCustomerNotPermitted"
        )}
        button1LabelChildren={
          displayInfoText(
            compensationPermittedToCustomerValue,
            "true",
            modificationData
          ) && (
            <DateAndEditor
              id="compensationInstructions-date-editor-customer-permitted"
              modificationData={modificationData}
              user={user}
            />
          )
        }
        button2LabelChildren={
          displayInfoText(
            compensationPermittedToCustomerValue,
            "false",
            modificationData
          ) && (
            <DateAndEditor
              id="compensationInstructions-date-editor-customer-not-permitted"
              modificationData={modificationData}
              user={user}
            />
          )
        }
        error={spdCustomerCompensationPermittedError != null}
        helperText={getValidationErrorMessage(
          spdCustomerCompensationPermittedError,
          t
        )}
      />
      {isCompensationToCustomerPermitted === false && (
        <Box mt={2} mx={4}>
          <Box mb={4}>
            <WarningMessage
              text={t(
                "compensationInstructions.componsationToCustomerNotPermittedWarning"
              )}
              type="warning"
            />
          </Box>
          <TitleInput
            disabled={disabled}
            title={t("compensationInstructions.reasonForCompensationRejection")}
            value={reasonForRejection}
            onChange={setReasonForRejection}
            rows={4}
            enableCopyButton
            error={spdCustomerCompensationReasonRejectionError != null}
            helperText={getValidationErrorMessage(
              spdCustomerCompensationReasonRejectionError,
              t
            )}
          />
          {hasModificationData("reasonForRejection") && (
            <DateAndEditor
              id="compensationInstructions-date-editor-reason-for-rejection"
              modificationData={getModificationData("reasonForRejection")}
              user={user}
            />
          )}
        </Box>
      )}
      <Box mt={4}>
        <TitleInput
          disabled={disabled}
          mt={4}
          title={t("compensationInstructions.title")}
          secondaryTitle={t("optional")}
          value={compensationInstructionsText}
          onChange={setCompensationInstructionsText}
          rows={4}
          enableCopyButton
        />
        {hasModificationData("compensationInstructions") && (
          <DateAndEditor
            id="compensationInstructions-date-editor-instructions"
            modificationData={getModificationData("compensationInstructions")}
            user={user}
          />
        )}
      </Box>
    </Box>
  );
}

CompensationInstructions.propTypes = {
  user: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  currentUserIsPharmaceuticalCompanyEditRole: PropTypes.bool,
};

CompensationInstructions.defaultProps = {
  disabled: false,
  currentUserIsPharmaceuticalCompanyEditRole: false,
};

export default CompensationInstructions;
