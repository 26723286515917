import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import ScopedSearch from "@oriola-origo/core/lib/ScopedSearch";

export const FILTERS = [
  "caseNumber",
  "customerId",
  "customerName",
  "orderNumber",
  "VNR",
  "MSI",
  "productName",
  "materialId",
  "shippingBoxId",
  "principalReferenceNumber",
  "profitCenterName",
  "profitCenterId",
  "trackingCode",
  "batchNumber",
];

export const handleFilterChange = (setSearchFilter, filter) => {
  setSearchFilter(filter);
};
function SearchField({
  searchText,
  selectedFilter,
  onSearchTextChanged,
  placeholder,
  label,
  t,
  ...containerProps
}) {
  const [searchFilter, setSearchFilter] = useState(selectedFilter);
  const [inputText, setInputText] = useState(searchText);

  useEffect(() => {
    onSearchTextChanged({ searchText: inputText, searchFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText, searchFilter]);

  return (
    <Box
      width="640px"
      display="flex"
      {...containerProps}
      id="case-filter-search"
    >
      <ScopedSearch
        onScopeChange={filter => handleFilterChange(setSearchFilter, filter)}
        onTextChange={inputSearchText => setInputText(inputSearchText)}
        scopes={FILTERS.map(filter => ({
          text: t(`caseSearchFilters.${filter}`),
          id: filter,
        }))}
        selectedScope={searchFilter}
        sx={{
          width: "600px",
        }}
        textValue={inputText}
        label={placeholder}
      />
    </Box>
  );
}

SearchField.propTypes = {
  searchText: PropTypes.string,
  selectedFilter: PropTypes.string.isRequired,
  onSearchTextChanged: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  t: PropTypes.func,
};

SearchField.defaultProps = {
  searchText: "",
  placeholder: "",
  label: "",
  t: () => {},
};

export default SearchField;
