import React from "react";
import PropTypes from "prop-types";
import { Button, IconTextLabel, Progress } from "@oriola-origo/origo-ui-core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchExportToExcelUrl,
  addNotification,
  ExportReportType,
} from "../../redux";
import classNames from "../../utils/classNames/classNames";
import { ImportExcelIcon } from "../../images";

export { ExportReportType } from "../../redux";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
  },
  hidden: {
    visibility: "hidden",
    pointerEvents: "none",
  },
  progress: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

function ExportReport({ classes, reportType, filtersWithOrg }) {
  const { fetchingExcelReport } = useSelector(state => state.listCases);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const internalClasses = useStyles();

  const exportToExcel = () => {
    dispatch(fetchExportToExcelUrl(filtersWithOrg, reportType, i18n.language))
      .then(response => {
        window.open(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error("Error when importing the excel", error);
        dispatch(
          addNotification({
            title: t("exportToExcelFailed.title"),
            primaryText: t("exportToExcelFailed.description"),
            type: "error",
          })
        );
      });
  };
  const buttonLabelLocaleKey =
    reportType === ExportReportType.RECLAMATIONS
      ? "exportToExcel"
      : "exportQualityToExcel";

  const isLoading = fetchingExcelReport === reportType;

  const buttonClasses = classNames(
    classes.exportButton,
    isLoading ? internalClasses.hidden : null
  );

  return (
    <div
      className={classNames(
        classes.exportButtonWrapper,
        internalClasses.container
      )}
    >
      {isLoading && (
        <Progress
          className={classNames(classes.exportLoader, internalClasses.progress)}
          show
          size={22}
        />
      )}
      <Button
        className={buttonClasses}
        onClick={exportToExcel}
        id="import-excel-button"
      >
        <IconTextLabel
          icon={<ImportExcelIcon />}
          text={t(buttonLabelLocaleKey)}
          variant="body1"
        />
      </Button>
    </div>
  );
}

ExportReport.propTypes = {
  filtersWithOrg: PropTypes.shape({}),
  classes: PropTypes.shape({
    exportButtonWrapper: PropTypes.string,
    exportLoader: PropTypes.string,
    exportButton: PropTypes.string,
  }),
  reportType: PropTypes.string.isRequired,
};

ExportReport.defaultProps = {
  filtersWithOrg: {},
  classes: {
    exportButtonWrapper: "",
    exportLoader: "",
    exportButton: "",
  },
};

export default ExportReport;
