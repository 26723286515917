/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import DateAndEditor from "../dateAndEditor";
import { TitleInput } from "../../common";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import { checkIfNewReturnAddressFormat } from "../../../utils/case/case";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import { Attachments } from "../../fields/common";
import CheckboxText from "./checkboxText";
import ReturnAddressV2 from "./returnAddress/returnAddressV2";

const useStyles = makeStyles(theme => ({
  text14: {
    ...theme.typography.button,
    textTransform: "none",
    fontWeight: "400",
    color: OriolaColors.secondaryNavyBlue,
  },
  checkbox: {
    color: OriolaColors.secondaryNavyBlue,
    "&.Mui-checked": {
      color: OriolaColors.secondaryNavyBlue,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 0,
  },
  checkboxError: {
    color: OriolaColors.errorRed,
  },
  helperText: {
    ...theme.typography.button,
    textTransform: "none",
    color: OriolaColors.errorRed,
    marginTop: theme.spacing(1),
  },
}));

function ReturnToPrincipal({
  productHandlingInstructions,
  modifications,
  user,
  disabled,
  ...containerProps
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isReturnToPrincipalRequired, isAuthorizedRecipientConfirmed } =
    productHandlingInstructions;
  // New implementation
  const returnAddress = productHandlingInstructions.returnAddress || {};

  const {
    spdProductReturnRequiredToPrincipalRequired,
    spdReturnToPrincipalAddressRequired,
    spdReturnToPrincipalRecipientConfirmationRequired,
  } = useSelector(state => state.validationErrors);
  const { case: reclamationCase, localHandlingData = {} } = useSelector(
    state => state.case
  );
  const [existingAttachments, setExistingAttachments] = useState([]);
  const [attachmentError, setAttachmentError] = useState(null);

  useEffect(() => {
    const attachments =
      (localHandlingData.productHandlingInstructions &&
        localHandlingData.productHandlingInstructions.attachments) ||
      [];
    setExistingAttachments(attachments);
  }, [localHandlingData, productHandlingInstructions]);

  const handleOnAttachmentsUpload = attachments =>
    dispatch(
      updateLocalHandlingDataField(
        attachments,
        "productHandlingInstructions",
        "attachments"
      )
    );

  const setIsAuthorizedRecipientConfirmed = newValue => {
    // clear validation errors
    dispatch(
      updateValidationError({
        spdReturnToPrincipalRecipientConfirmationRequired: null,
      })
    );

    dispatch(
      updateLocalHandlingDataField(
        newValue,
        "productHandlingInstructions",
        "isAuthorizedRecipientConfirmed"
      )
    );
  };

  const setReturnRequiredToPrincipal = newValue => {
    // clear validation errors
    dispatch(
      updateValidationError({
        spdProductReturnRequiredToPrincipalRequired: null,
      })
    );

    const newReturnAddress = newValue === true ? returnAddress : null;

    dispatch(
      updateLocalHandlingDataField(
        newValue,
        "productHandlingInstructions",
        "isReturnToPrincipalRequired"
      )
    );

    dispatch(
      updateLocalHandlingDataField(
        newReturnAddress,
        "productHandlingInstructions",
        "returnAddress"
      )
    );

    // clear authorization since changed
    setIsAuthorizedRecipientConfirmed(null);
  };

  const setReturnAddress = newReturnAddress => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdReturnToPrincipalAddressRequired: null })
    );

    dispatch(
      updateLocalHandlingDataField(
        newReturnAddress,
        "productHandlingInstructions",
        "returnAddress"
      )
    );

    // clear authorization since changed
    setIsAuthorizedRecipientConfirmed(null);
  };

  const getModificationData = field =>
    getSpdModificationData(
      modifications,
      `productHandlingInstructions.${field}`
    );

  const hasModificationData = field => !!getModificationData(field);

  const isNewReturnAddressFormat = checkIfNewReturnAddressFormat(returnAddress);

  const renderReturnAddressComponent = renderNewReturnAddress => {
    return renderNewReturnAddress
      ? renderNewReturnAddressComponent()
      : renderLegacyReturnAddressComponent();
  };
  const renderLegacyReturnAddressComponent = () => {
    return (
      <Box>
        <TitleInput
          disabled={disabled}
          inputClassName={disabled ? classes.input : null}
          value={returnAddress || ""}
          placeholder={t(
            "productHandlingInstructions.returnAddressPlaceholder"
          )}
          onChange={setReturnAddress}
          rows={8}
          label={t("productHandlingInstructions.returnAddress")}
          enableCopyButton
          error={
            spdReturnToPrincipalAddressRequired != null &&
            isReturnToPrincipalRequired === true
          }
          helperText={getValidationErrorMessage(
            spdReturnToPrincipalAddressRequired,
            t
          )}
          id="product-handling-instructions-return-address"
          InputLabelProps={{ shrink: true }}
        />
        {hasModificationData("returnAddress") && (
          <DateAndEditor
            id="productHandlingInstructions-date-editor-return-address"
            modificationData={getModificationData("returnAddress")}
            user={user}
          />
        )}
      </Box>
    );
  };
  const renderNewReturnAddressComponent = () => {
    return (
      <ReturnAddressV2
        user={user}
        disabled={disabled}
        returnAddress={returnAddress}
        isReturnToPrincipalRequired={isReturnToPrincipalRequired}
        getModificationData={getModificationData}
        setIsAuthorizedRecipientConfirmed={setIsAuthorizedRecipientConfirmed}
      />
    );
  };
  return (
    <Box {...containerProps}>
      <CheckboxText
        id="product-handling-instructions-return-to-principal-checkbox"
        checked={isReturnToPrincipalRequired === true}
        validationError={spdProductReturnRequiredToPrincipalRequired}
        disabled={disabled}
        text={t("productHandlingInstructions.productReturnRequiredToPrincipal")}
        modificationData={getModificationData("isReturnToPrincipalRequired")}
        onChange={e => setReturnRequiredToPrincipal(e.target.checked)}
        dateAndEditorId="productHandlingInstructions-date-editor-return-required"
        user={user}
      />
      {isReturnToPrincipalRequired === true && (
        <Box mx={4} mt={2}>
          {reclamationCase?.items[0]?.product?.isFlammableProduct && (
            <WarningMessage
              text={t("productHandlingInstructions.flammableProductWarning")}
              type="warning"
            />
          )}
          {renderReturnAddressComponent(isNewReturnAddressFormat)}
          <Box mt={2}>
            <CheckboxText
              id="product-handling-instructions-recipient-authorization-checkbox"
              checked={isAuthorizedRecipientConfirmed === true}
              validationError={
                spdReturnToPrincipalRecipientConfirmationRequired
              }
              disabled={disabled}
              text={t("productHandlingInstructions.confirmRecipientAuthorized")}
              modificationData={getModificationData(
                "isAuthorizedRecipientConfirmed"
              )}
              onChange={e =>
                setIsAuthorizedRecipientConfirmed(e.target.checked)
              }
              dateAndEditorId="productHandlingInstructions-date-editor-confirm-recipient"
              user={user}
            />
          </Box>
          <Attachments
            reclamationCase={reclamationCase}
            disabled={disabled}
            id="productHandlingInstructions-attachments-upload"
            existingAttachments={existingAttachments}
            onAttachmentsUpload={handleOnAttachmentsUpload}
            attachmentError={attachmentError}
            setAttachmentError={setAttachmentError}
          />
        </Box>
      )}
    </Box>
  );
}

ReturnToPrincipal.propTypes = {
  productHandlingInstructions: PropTypes.shape({
    isReturnToPrincipalRequired: PropTypes.bool,
    returnAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isAuthorizedRecipientConfirmed: PropTypes.bool,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  modifications: PropTypes.shape({}),
  user: PropTypes.shape({}),
  disabled: PropTypes.bool,
  productHandlingInstructionsModificationData: PropTypes.shape({}),
};

ReturnToPrincipal.defaultProps = {
  modifications: {},
  productHandlingInstructions: {},
  user: null,
  disabled: false,
  productHandlingInstructionsModificationData: null,
};

export default ReturnToPrincipal;
