import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { CssTextField, StatefullTextField } from "../../../common";
import AutoComplete from "../../../generic/autoComplete/autoComplete";
import {
  searchProfitCenters,
  clearProfitCenters,
  getProfitCenterDetails,
} from "../../../../redux";
import { isOriolaUser } from "../../../auth/permission";

function ProductPrincipal({ classes, id, product, updateItem, itemData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profitCenters, fetching } = useSelector(state => state.profitCenters);
  const userData = useSelector(state => state.user.userData);
  const currentUserIsOriolaUser = isOriolaUser(userData);

  useEffect(() => {
    dispatch(clearProfitCenters());
  }, [dispatch]);

  useEffect(() => {
    if (product.profitCenter) {
      dispatch(getProfitCenterDetails(product.profitCenter));
    }
  }, [dispatch, product.profitCenter]);

  const getOptionRow = profitCenterOption => {
    if (typeof profitCenterOption === "string") {
      return profitCenterOption;
    }
    if (!profitCenterOption.number) {
      return profitCenterOption.name || "";
    }
    return profitCenterOption
      ? `${profitCenterOption.number || ""} - ${profitCenterOption.name || ""}`
      : "";
  };

  const onChange = value => {
    dispatch(
      updateItem({
        ...itemData,
        product: {
          ...product,
          profitCenterName: value,
        },
      })
    );
  };

  const onSelectProfitCenter = selectedProfitCenter => {
    if (selectedProfitCenter) {
      dispatch(
        updateItem({
          ...itemData,
          product: {
            ...product,
            profitCenter: selectedProfitCenter.number,
            profitCenterName: selectedProfitCenter.name,
          },
        })
      );
    }
  };

  const renderProfitCenterSearchBar = () => {
    const principalValue = {
      name: product.profitCenterName,
      number: product.profitCenter,
    };
    return (
      <Box width={350}>
        <AutoComplete
          value={principalValue}
          onChange={value => {
            dispatch(searchProfitCenters(value));
          }}
          options={profitCenters}
          getOptionName={getOptionRow}
          onSelect={onSelectProfitCenter}
          placeholder={t("caseItemFields.principalSearchPlaceHolder")}
          id={`${id}-search-profit-center`}
          fetching={fetching}
        />
      </Box>
    );
  };

  const renderPrincipal = () => {
    if (product.customerAddedProduct && currentUserIsOriolaUser) {
      return renderProfitCenterSearchBar();
    }
    if (product.customerAddedProduct) {
      return (
        <StatefullTextField
          className={classes.stringField}
          initialValue={product.profitCenterName || ""}
          variant="outlined"
          placeholder={t("caseItemFields.principal")}
          margin="dense"
          id={`${id}-principal`}
          onChange={onChange}
        />
      );
    }
    return (
      <CssTextField
        className={classes.stringField}
        value={product.profitCenterName || ""}
        variant="outlined"
        placeholder={t("caseItemFields.principal")}
        margin="dense"
        disabled
        id={`${id}-principal`}
      />
    );
  };

  return (
    <div>
      <FieldTitle
        title={t("caseItemFields.principal")}
        type={
          product.customerAddedProduct && currentUserIsOriolaUser
            ? "selection"
            : ""
        }
      />
      <div className={classes.controlContainer}>{renderPrincipal()}</div>
    </div>
  );
}

ProductPrincipal.propTypes = {
  classes: PropTypes.shape({
    controlContainer: PropTypes.string,
    stringField: PropTypes.string,
  }),
  id: PropTypes.string,
  product: PropTypes.shape({
    profitCenter: PropTypes.string,
    profitCenterName: PropTypes.string,
    customerAddedProduct: PropTypes.bool,
  }),
  updateItem: PropTypes.func,
  itemData: PropTypes.shape({}),
};

ProductPrincipal.defaultProps = {
  product: {},
  updateItem: null,
  itemData: {},
  classes: {},
  id: "",
};

export default ProductPrincipal;
