import React from "react";
import { Box } from "@material-ui/core";
import { ErrorMessage } from "@oriola-origo/origo-ui-core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { YesNoSelection, YesNoSelectionValues } from "../../common";
import RejectionReason from "./rejectionReason";
import Compensations from "./compensations";
import {
  updateLocalhandlingData,
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import Title from "../title/title";
import DateAndEditor from "../dateAndEditor";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import ResetButton from "../../common/resetButton/resetButton";

function CompensationEdit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const { compensationPermittedError } = useSelector(
    state => state.validationErrors
  );

  const getCompensationPermittedValue = () => {
    if (
      localHandlingData == null ||
      localHandlingData.compensationPermitted == null
    ) {
      return "";
    }
    return localHandlingData.compensationPermitted === true
      ? YesNoSelectionValues.Yes
      : YesNoSelectionValues.No;
  };

  const compensations = localHandlingData.compensations
    ? localHandlingData.compensations.map((compensation, index) => ({
        ...compensation,
        index,
      }))
    : [];

  const getRelevantCompensationIndex = (compensationArray, compensation) =>
    compensationArray.findIndex(c => c.index === compensation.index);

  const { userData } = useSelector(state => state.user);
  const { modifications = {} } = localHandlingData;
  const getModificationData = field =>
    getSpdModificationData(modifications, `${field}`);

  const renderPermitted = () => (
    <Compensations
      compensations={compensations}
      onCompensationUpdated={compensation => {
        const index = getRelevantCompensationIndex(compensations, compensation);
        if (index !== -1) {
          compensations.splice(index, 1, compensation);
        } else {
          compensations.push(compensation);
        }
        dispatch(updateLocalhandlingData({ compensations }));
      }}
      onCompensationRemoved={compensation => {
        const index = getRelevantCompensationIndex(compensations, compensation);
        compensations.splice(index, 1);
        dispatch(updateLocalhandlingData({ compensations }));
      }}
      onAddNewCompensation={compensation => {
        dispatch(
          updateLocalhandlingData({
            compensations: [...compensations, compensation],
          })
        );
      }}
    />
  );

  const renderNotPermitted = () => (
    <>
      <RejectionReason
        handlingData={localHandlingData}
        onReasonChanged={reason => {
          dispatch(
            updateLocalHandlingDataField(reason, "compensationRejectionReason")
          );
          dispatch(updateValidationError({ rejectReasonError: "" }));
          dispatch(updateValidationError({ rejectDescriptionError: "" }));
        }}
        onReasonDescriptionChanged={value => {
          dispatch(
            updateLocalHandlingDataField(
              value,
              "compensationRejectionDescription"
            )
          );
          dispatch(updateValidationError({ rejectDescriptionError: "" }));
        }}
      />
      {getModificationData("compensationRejectionReason") && (
        <DateAndEditor
          modificationData={{
            ...getModificationData("compensationRejectionReason"),
            hideModifierName: true,
          }}
          user={userData}
        />
      )}
    </>
  );

  const resetCompensationIsPermittedValue = () => {
    dispatch(updateValidationError({ compensationPermittedError: null }));
    dispatch(updateLocalHandlingDataField(undefined, "compensationPermitted"));
  };

  const isResetDisabled = () => getCompensationPermittedValue() === "";

  return (
    <Box className="handling-section">
      <Box display="flex" style={{ justifyContent: "space-between" }}>
        <Title title={t("compensation.compensation")} />
        <ResetButton
          onClick={resetCompensationIsPermittedValue}
          disabled={isResetDisabled()}
        />
      </Box>
      <Box>
        <YesNoSelection
          id="compensation-permitted-selection"
          title={t("compensationIsPermitted")}
          error={false}
          errorText=""
          value={getCompensationPermittedValue()}
          onValueChanged={event => {
            const value = event.target.value === YesNoSelectionValues.Yes;
            dispatch(
              updateLocalHandlingDataField(value, "compensationPermitted")
            );
            dispatch(updateValidationError({ compensationPermittedError: "" }));
          }}
        />
        {getModificationData("compensationPermitted") && (
          <DateAndEditor
            modificationData={{
              ...getModificationData("compensationPermitted"),
              hideModifierName: true,
            }}
            user={userData}
          />
        )}
      </Box>
      {compensationPermittedError && (
        <ErrorMessage
          text={getValidationErrorMessage(compensationPermittedError, t)}
        />
      )}
      {getCompensationPermittedValue() === YesNoSelectionValues.Yes &&
        renderPermitted()}
      {getCompensationPermittedValue() === YesNoSelectionValues.No &&
        renderNotPermitted()}
    </Box>
  );
}

CompensationEdit.propTypes = {};

CompensationEdit.defaultProps = {};

export default CompensationEdit;
