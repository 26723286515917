import * as _ from "lodash";
/* eslint-disable */
import { SendingStatus } from "..";
import { getFieldModificationData } from "../../utils/getSpdModificationData/getSpdModificationData";
import {
  deleteNested,
  setNested,
  getOriginalAttachments,
  updateAttachments,
  getUnhandledItems,
} from "./caseUtils";
import { baseUrl, Case, reclamationApi } from "./constants";

import { RestService } from "../../components/common";
import { CaseType } from "../../constants/caseType";
import Operation from "../../constants/operation";
import { ProductDisposers } from "../../components/case/productHandlingInstructions/productHandlingInstructions";
import { ProductReceivedOrNot } from "../../components/case/productHandlingInformation/productHandlingInformation";

const objectPath = require("object-path");

const getSPDHandlingDataSavedValue = (state, ...fieldPath) => {
  const savedHandlingData = state.case.case.items[0].handlingData;
  const savedValue = objectPath.get(savedHandlingData, fieldPath);
  return savedValue == null ? "" : savedValue;
};

const getSPDHandlingDataSavedModificationsValue = (state, ...fieldPath) => {
  const savedHandlingData = state.case.case.items[0].handlingData || {};
  const originalModificationData = savedHandlingData.modifications || {};
  return objectPath.get(originalModificationData, fieldPath);
};

export const updateLocalhandlingData =
  (updatedHandlingData, modifications = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const prevHandlingData = state.case.localHandlingData || {};
    const handlingData = {
      ...prevHandlingData,
      ...updatedHandlingData,
      modifications: { ...prevHandlingData.modifications, ...modifications },
    };
    return dispatch({
      type: Case.UPDATE_MODIFIED_HANDLING_DATA,
      payload: handlingData,
    });
  };

export const updateLocalHandlingDataField =
  (newValue, ...fieldPath) =>
  async (dispatch, getState) => {
    const state = getState();
    const prevHandlingData = state.case.localHandlingData || {};
    const isNotLocalChange = fieldPath.includes("notLocalChange");
    fieldPath = fieldPath.filter(val => val !== "notLocalChange");
    const savedValue = getSPDHandlingDataSavedValue(state, ...fieldPath);
    const isNewValueSameAsSaved = newValue === savedValue;
    const isDelete = newValue === undefined || newValue === null;
    const modifications = _.cloneDeep(prevHandlingData.modifications || {});
    if (isNewValueSameAsSaved) {
      const mod = getSPDHandlingDataSavedModificationsValue(
        state,
        ...fieldPath
      );
      setNested(modifications, mod, ...fieldPath);
    } else if (
      isDelete &&
      (savedValue === undefined || savedValue === "" || savedValue === null)
    ) {
      // Delete modification data in case it has not been saved before (= no value in saved data)
      // Otherwise, it's considered as a local change
      deleteNested(modifications, ...fieldPath);
    } else {
      let mod = getFieldModificationData(state.user.userData);
      if (isNotLocalChange) mod = { ...mod, isLocalChange: false };
      setNested(modifications, mod, ...fieldPath);
    }
    const updatedHandlingData = _.cloneDeep(prevHandlingData);
    if (isDelete) {
      deleteNested(updatedHandlingData, ...fieldPath);
    } else {
      setNested(updatedHandlingData, newValue, ...fieldPath);
    }

    const handlingData = {
      ...updatedHandlingData,
      modifications,
    };
    return dispatch({
      type: Case.UPDATE_MODIFIED_HANDLING_DATA,
      payload: handlingData,
    });
  };

export const setLocalHandlingData = handlingData => dispatch =>
  dispatch({
    type: Case.UPDATE_MODIFIED_HANDLING_DATA,
    payload: handlingData,
  });

export const removeItemHandligData = itemUuid => async (dispatch, getState) => {
  try {
    const state = getState();

    const { reclamationId, version } = state.case.case;

    dispatch({ type: Case.HANDLING_DATA_SEND_STARTED });

    const path = `${baseUrl}${reclamationApi}/${
      reclamationId || ""
    }/${version}/handlingData/${itemUuid}`;
    const saveResult = await RestService.delete(path);

    dispatch({ type: Case.HANDLING_DATA_SEND_FINISHED, payload: saveResult });
    return {
      status: SendingStatus.SUCCESS,
      data: saveResult,
    };
  } catch (err) {
    dispatch({ type: Case.HANDLING_DATA_SEND_ERROR, payload: err });
    return {
      status: SendingStatus.ERROR,
      data: err,
    };
  }
};

export const clearlocalHandlingData = () => dispatch => {
  dispatch({ type: Case.UPDATE_CASE_HANDLING_ITEMS, payload: [] });
  return dispatch({ type: Case.UPDATE_MODIFIED_HANDLING_DATA, payload: {} });
};

const prepareDefaultHandlingData = (
  caseHandlingItems,
  handlingData,
  closeCase = false
) => {
  const itemUuids = (caseHandlingItems || []).map(x => x.uuid).filter(x => x);

  if (closeCase) {
    delete handlingData.storageLocation;
  }

  return {
    ...handlingData,
    itemUuids,
    compensations: handlingData.compensationPermitted
      ? handlingData.compensations
      : [],
    compensationRejectionReason: handlingData.compensationPermitted
      ? null
      : handlingData.compensationRejectionReason,
    compensationRejectionDescription: handlingData.compensationPermitted
      ? null
      : handlingData.compensationRejectionDescription,
    operation: closeCase ? Operation.CLOSE : undefined,
  };
};

export const prepareConclusionData = (reclamationCase, handlingData) => {
  const itemUuids = [reclamationCase.items[0].uuid];
  const { conclusion } = handlingData;
  if (conclusion && conclusion.attachments) {
    conclusion.attachments = getOriginalAttachments(conclusion.attachments);
  }
  return {
    ...handlingData,
    itemUuids,
    conclusion,
    type: CaseType.SUSPECTED_PRODUCT_DEFECT,
  };
};
export const prepareSpdHandlingData = (
  caseHandlingItems,
  handlingData,
  closeCase = false
) => {
  const itemUuids = (caseHandlingItems || []).map(x => x.uuid).filter(x => x);
  const {
    productHandlingInstructions,
    productHandlingInformation,
    compensationInstructions,
    compensationInformation,
    conclusion,
  } = _.cloneDeep(handlingData);
  if (productHandlingInstructions) {
    if (
      productHandlingInstructions.methodOfDisposal === ProductDisposers.CUSTOMER
    ) {
      delete productHandlingInstructions.isCertificateOfDestructionRequired;
    }
    if (!productHandlingInstructions.isReturnToPrincipalRequired) {
      delete productHandlingInstructions.returnAddress;
      delete productHandlingInstructions.attachments;
      delete productHandlingInstructions.isAuthorizedRecipientConfirmed;
    }
    if (productHandlingInstructions.attachments) {
      productHandlingInstructions.attachments = getOriginalAttachments(
        productHandlingInstructions.attachments
      );
    }
  }

  if (
    productHandlingInformation &&
    productHandlingInformation.productReceived ===
      ProductReceivedOrNot.PRODUCT_NOT_RETURNED
  ) {
    delete productHandlingInformation.isDisposedAtOriola;
    delete productHandlingInformation.isPhotographsTaken;
    delete productHandlingInformation.isSentToPharmaCompany;
    delete productHandlingInformation.amountReturned;
  }
  if (productHandlingInformation?.attachments) {
    productHandlingInformation.attachments = getOriginalAttachments(
      productHandlingInformation.attachments
    );
  }

  if (
    compensationInstructions &&
    compensationInstructions.isCompensationToCustomerPermitted
  ) {
    delete compensationInstructions.reasonForRejection;
  }

  if (
    compensationInformation &&
    !compensationInformation.isCustomerCompensated
  ) {
    delete compensationInformation.compensations;
  }

  if (conclusion && conclusion.attachments) {
    conclusion.attachments = getOriginalAttachments(conclusion.attachments);
  }

  if (!handlingData.trackingCode) {
    delete handlingData.trackingCode;
  }

  if (
    caseHandlingItems[0].handlingData?.trackingCode !==
    handlingData?.trackingCode
  ) {
    handlingData.isTrackingCodeReadConfirmed = false;
  }

  if (closeCase) {
    delete handlingData.storageLocation;
  }

  return {
    ...handlingData,
    productHandlingInstructions,
    productHandlingInformation,
    compensationInstructions,
    compensationInformation,
    itemUuids,
    conclusion,
    type: CaseType.SUSPECTED_PRODUCT_DEFECT,
    operation: closeCase ? Operation.CLOSE : undefined,
  };
};

export const updateAttachmentsByFields = async (
  localHandlingData,
  element,
  reclamationId,
  newVersion,
  userData
) => {
  if (
    localHandlingData[element] &&
    "attachments" in localHandlingData[element]
  ) {
    const updateAttachmentsResult = await updateAttachments(
      localHandlingData[element].attachments,
      reclamationId,
      newVersion
    );
    newVersion = updateAttachmentsResult.version;
    localHandlingData[element].attachments =
      updateAttachmentsResult.attachments;
    localHandlingData[element].attachments.forEach(data => {
      // Skip patching attachments where there are already username & ID of the uploader
      if (!(data.userName && data.userId)) {
        data.userName = userData.name;
        data.userId = userData.userId;
      }
    });
    return { localHandlingData, newVersion };
  }
  return { localHandlingData, newVersion };
};

export const sendlocalHandlingData =
  (closeCase = false) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const { sendingHandlingData, caseHandlingItems, localHandlingData } =
        state.case;
      const { userData } = state.user;

      const reclamationCase = state.case.case;
      const { reclamationId, version } = reclamationCase;

      if (sendingHandlingData) {
        return {
          status: SendingStatus.SENDING,
        };
      }
      let preparedHandlingData;

      const isSpdCase =
        reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT;
      if (isSpdCase) {
        preparedHandlingData = prepareSpdHandlingData(
          caseHandlingItems,
          localHandlingData,
          closeCase
        );
      } else {
        preparedHandlingData = prepareDefaultHandlingData(
          caseHandlingItems,
          localHandlingData,
          closeCase
        );
      }

      dispatch({ type: Case.HANDLING_DATA_SEND_STARTED });
      const path = `${baseUrl}${reclamationApi}/${
        reclamationId || ""
      }/${version}/handlingData`;
      // save case data except attachments
      const firstPutRequestData = preparedHandlingData;
      const firstResult = await RestService.put(path, firstPutRequestData);
      let saveResult = firstResult;
      if (isSpdCase) {
        let newVersion = firstResult.version;
        const attachmentFields = [
          "productHandlingInstructions",
          "conclusion",
          "productHandlingInformation",
        ];
        let data;

        for (const element of attachmentFields) {
          data = await updateAttachmentsByFields(
            localHandlingData,
            element,
            reclamationId,
            newVersion,
            userData
          );
          newVersion = data.newVersion;
        }
        const secondPutRequestData = prepareSpdHandlingData(
          caseHandlingItems,
          data.localHandlingData,
          closeCase
        );
        const updatePath = `${baseUrl}${reclamationApi}/${
          reclamationId || ""
        }/${newVersion}/handlingData`;
        saveResult = await RestService.put(updatePath, secondPutRequestData);
      }

      dispatch({ type: Case.HANDLING_DATA_SEND_FINISHED, payload: saveResult });

      return {
        status: SendingStatus.SUCCESS,
        data: saveResult,
      };
    } catch (err) {
      dispatch({ type: Case.HANDLING_DATA_SEND_ERROR, payload: err });
      return {
        status: SendingStatus.ERROR,
        data: err,
      };
    }
  };

export const submitConclusion = () => async (dispatch, getState) => {
  const state = getState();
  const reclamationCase = state.case.case;
  const { localHandlingData } = state.case;
  const { userData } = state.user;
  const { reclamationId, version } = reclamationCase;
  try {
    const path = `${baseUrl}${reclamationApi}/${
      reclamationId || ""
    }/${version}/handlingData`;
    const clonedHandlingData = _.cloneDeep(localHandlingData || {});
    const preparedHandlingData = prepareConclusionData(
      reclamationCase,
      clonedHandlingData
    );
    const firstResult = await RestService.put(path, preparedHandlingData);
    let saveResult = firstResult;
    const newVersion = firstResult.version;
    const data = await updateAttachmentsByFields(
      localHandlingData,
      "conclusion",
      reclamationId,
      newVersion,
      userData
    );
    const secondPutRequestData = prepareConclusionData(
      reclamationCase,
      data.localHandlingData
    );
    const updatePath = `${baseUrl}${reclamationApi}/${reclamationId || ""}/${
      data.newVersion
    }/handlingData`;
    saveResult = await RestService.put(updatePath, secondPutRequestData);
    dispatch({ type: Case.HANDLING_DATA_SEND_FINISHED, payload: saveResult });

    return {
      status: SendingStatus.SUCCESS,
      data: saveResult,
    };
  } catch (err) {
    dispatch({ type: Case.HANDLING_DATA_SEND_ERROR, payload: err });
    return {
      status: SendingStatus.ERROR,
      data: err,
    };
  }
};

export const addCaseHandlingItems = items => (dispatch, getState) => {
  const state = getState();
  const itemsKeyValue = {};
  let handlingData = {};
  const unhandledItems = getUnhandledItems(items, state.case.case);
  unhandledItems.forEach(item => {
    itemsKeyValue[item.uuid] = item.uuid;
  });
  unhandledItems.forEach(item => {
    handlingData = {
      ...handlingData,
      ...item.handlingData,
    };
  });
  dispatch(setLocalHandlingData(handlingData));
  const oldItems = (state.case.caseHandlingItems || []).filter(
    x => !itemsKeyValue[x.uuid]
  );
  const newItems = [...oldItems, ...unhandledItems];

  return dispatch({ type: Case.UPDATE_CASE_HANDLING_ITEMS, payload: newItems });
};

export const removeCaseHandlingItems = items => (dispatch, getState) => {
  const state = getState();
  const itemsKeyValue = {};
  items.forEach(item => {
    itemsKeyValue[item.uuid] = item.uuid;
  });
  const newItems = (state.case.caseHandlingItems || []).filter(
    x => !itemsKeyValue[x.uuid]
  );
  return dispatch({ type: Case.UPDATE_CASE_HANDLING_ITEMS, payload: newItems });
};

export const initializeCaseHandlingItems = items => (dispatch, getState) => {
  const state = getState();
  const unhandledItems = getUnhandledItems(items, state.case.case);
  return dispatch({
    type: Case.UPDATE_CASE_HANDLING_ITEMS,
    payload: unhandledItems,
  });
};
