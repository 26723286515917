import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import { RadioButtonSelection } from "../../common";
import DateAndEditor from "../dateAndEditor";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import Title from "../title/title";
import ResetButton from "../../common/resetButton/resetButton";

const useStyles = makeStyles(theme => ({
  titleAndResetButton: {
    display: "flex",
    justifyContent: "space-between",
  },
  resetButtonOriola: {
    position: "absolute",
    right: theme.spacing(11),
    top: theme.spacing(4),
  },
}));
function ProductHandling({
  user,
  disabled,
  currentUserIsOriolaUser,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const { isOriolaWaitingForInstructions, modifications = {} } =
    localHandlingData;
  const { spdOriolaIsWaitingForInstructions } = useSelector(
    state => state.validationErrors
  );

  const getOriolaWaitingForInstructionsValue = () => {
    if (isOriolaWaitingForInstructions == null) {
      return "";
    }
    return isOriolaWaitingForInstructions === true ? "true" : "false";
  };

  const setOriolaWaitingForInstructionsValue = updatedValue => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdOriolaIsWaitingForInstructions: null })
    );

    const booleanValue = updatedValue === "true";
    dispatch(
      updateLocalHandlingDataField(
        booleanValue,
        "isOriolaWaitingForInstructions"
      )
    );
  };

  const waitingForInstructionsValue = getOriolaWaitingForInstructionsValue();
  const modificationsData = getSpdModificationData(
    modifications,
    "isOriolaWaitingForInstructions"
  );

  const resetOriolaWaitingForInstructionsValue = () => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdOriolaIsWaitingForInstructions: null })
    );

    dispatch(
      updateLocalHandlingDataField(null, "isOriolaWaitingForInstructions")
    );
  };

  const displayInfoText = (controlValue, activeValue, modificationInfo) =>
    controlValue === activeValue ||
    (controlValue === undefined && modificationInfo?.isLocalChange);

  return (
    <Box id="product-handling" className="handling-section" {...containerProps}>
      <div className={classes.titleAndResetButton}>
        <Title title={t("productHandling.viewTitle")} />
        {currentUserIsOriolaUser && (
          <ResetButton
            className={classes.resetButtonOriola}
            onClick={resetOriolaWaitingForInstructionsValue}
            disabled={!waitingForInstructionsValue}
          />
        )}
      </div>
      <RadioButtonSelection
        button1LabelText={t("productHandling.yes")}
        button2LabelText={t("productHandling.no")}
        button1Value="true"
        button2Value="false"
        title={t("productHandling.title")}
        value={waitingForInstructionsValue}
        onChecked={setOriolaWaitingForInstructionsValue}
        disabled={disabled}
        button1LabelChildren={
          displayInfoText(
            isOriolaWaitingForInstructions,
            true,
            modificationsData
          ) && (
            <DateAndEditor
              id="product-handling-yes"
              modificationData={modificationsData}
              user={user}
            />
          )
        }
        button2LabelChildren={
          displayInfoText(
            isOriolaWaitingForInstructions,
            false,
            modificationsData
          ) && (
            <DateAndEditor
              id="product-handling-no"
              modificationData={modificationsData}
              user={user}
            />
          )
        }
        error={spdOriolaIsWaitingForInstructions != null}
        helperText={getValidationErrorMessage(
          spdOriolaIsWaitingForInstructions,
          t
        )}
      />
    </Box>
  );
}

ProductHandling.propTypes = {
  disabled: PropTypes.bool,
  user: PropTypes.shape({}),
  currentUserIsOriolaUser: PropTypes.bool,
};

ProductHandling.defaultProps = {
  disabled: false,
  user: null,
  currentUserIsOriolaUser: false,
};

export default ProductHandling;
