import moment from "moment";
import { CaseType } from "../../constants/caseType";

import CourierIds from "../../constants/couriers";
// eslint-disable-next-line import/no-cycle
import { validateAllItems, validateItemCount } from "./caseItemsValidator";
import { isInValidBoolean, isOverLength } from "./utils";
import { isCaseForOriolaByOriola } from "../case/case";
import validateProductReturn from "./validateProductReturn";
import {
  MIME_TYPES,
  ALLOWED_EXTENSIONS_LIST,
} from "../../constants/supportedFileFormats";
import UserType from "../../constants/userType";
import getUserType from "../user";

export { validateAllItems, validateItemCount };

export const CompensationErrors = Object.freeze({
  IDENTIFIER_CODE_ERROR: "IDENTIFIER_CODE_ERROR",
  COMPENSATION_NUMBER_ERROR: "COMPENSATION_NUMBER_ERROR",
  COMPENSATION_DATE_ERROR: "COMPENSATION_DATE_ERROR",
  HANDLER_ERROR: "HANDLER_ERROR",
});

export const ShippingBoxErrors = Object.freeze({
  SHIPPING_BOX_CODE__MANDATORY: "SHIPPING_BOX_CODE__MANDATORY",
  STORED_MANDATORY: "STORED_MANDATORY",
  DATE_MANDATORY: "DATE_MANDATORY",
  MORE_INFORMATION: "MORE_INFORMATION",
});

export const FieldErrors = Object.freeze({
  MANDATORY: "MANDATORY",
  OVER_LENGTH: "OVER_LENGTH",
  INVALID_LENGTH: "INVALID_LENGTH",
  INVALID_LENGTH_STRING: "INVALID_LENGTH_STRING",
  INVALID_LENGTH_CODE: "INVALID_LENGTH_CODE",
  INVALID_DATE: "INVALID_DATE",
  INVALID_NUMBER: "INVALID_NUMBER",
  MANDATORY_OR_NOT_AVAILABLE: "MANDATORY_OR_NOT_AVAILABLE",
  CUSTOMER_MANDATORY: "CUSTOMER_MANDATORY",
  NO_REQUIRED_ITEM: "NO_REQUIRED_ITEM",
  CONTACT_PERSON_MANDATORY: "CONTACT_PERSON_MANDATORY",
  PHONE_NUMBER_MANDATORY: "PHONE_NUMBER_MANDATORY",
  PHONE_NUMBER_INVALID: "PHONE_NUMBER_INVALID",
  REASON_IDENTIFIER_MANDATORY: "REASON_IDENTIFIER_MANDATORY",
  CASE_DESCRIPTION_MANDATORY: "CASE_DESCRIPTION_MANDATORY",
  CASE_DESCRIPTION_OVER_LENGTH: "CASE_DESCRIPTION_OVER_LENGTH",
  CONCLUSION_OVER_LENGTH: "CONCLUSION_OVER_LENGTH",
  RETURN_PRODUCT_MANDATORY: "RETURN_PRODUCT_MANDATORY",
  RETURN_AMOUNT_INCORRECT: "RETURN_AMOUNT_INCORRECT",
  RETURN_CONFIRMATION: "RETURN_CONFIRMATION",
  PRODUCT_RETURN_REQUIRED_MANDATORY: "PRODUCT_RETURN_REQUIRED_MANDATORY",
  PRODUCTS_HAVE_BEEN_RETURNED_MANDATORY: "PRODUCT_HAVE_BEEN_RETURNED_MANDATORY",
  PRODUCT_SELLABLE_MANDATORY: "PRODUCT_SELLABLE_MANDATORY",
  HANDLING_INFORMATION_OVER_LENGTH: "HANDLING_INFORMATION_OVER_LENGTH",
  COMPENSATION_PERMITTED_MANDATORY: "COMPENSATION_PERMITTED_MANDATORY",
  REJECTION_REASON_MANDATORY: "REJECTION_REASON_MANDATORY",
  REJECTION_DESCRIPTION_MANDATORY: "REJECTION_DESCRIPTION_MANDATORY",
  REJECTION_DESCRIPTION_OVER_LENGTH: "REJECTION_DESCRIPTION_OVER_LENGTH",
  MESSAGE_OVER_LENGTH: "MESSAGE_OVER_LENGTH",
  MESSAGE_MANDATORY: "MESSAGE_MANDATORY",
  QUEUE_MANDATORY: "QUEUE_MANDATORY",
  INVALID_RETURN_RECEIVE_AMOUNT: "INVALID_RETURN_RECEIVE_AMOUNT",
  INVALID_UNIQUE_SERIAL_NUMBER: "INVALID_UNIQUE_SERIAL_NUMBER",
  PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY:
    "PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY",
  PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RECIPIENT_CONFIRMATION_MISSING:
    "PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RECIPIENT_CONFIRMATION_MISSING",
  CERTIFICATION_OF_DISPOSAL_VALUE_MANDATORY:
    "CERTIFICATION_OF_DISPOSAL_VALUE_MANDATORY",
  CUSTOMER_COMPENSATION_REJECTED_REASON_MANDATORY:
    "CUSTOMER_COMPENSATION_REJECTED_REASON_MANDATORY",
  CUSTOMER_COMPENSATION_IS_COMPENSATED_MANDATORY:
    "CUSTOMER_COMPENSATION_IS_COMPENSATED_MANDATORY",
  CUSTOMER_COMPENSATION_IS_COMPENSATED_DETAILS_MANDATORY:
    "CUSTOMER_COMPENSATION_IS_COMPENSATED_DETAILS_MANDATORY",
  PERMISSION_REQUIRED_FROM_PHARMA_COMPANY_MANDATORY:
    "PERMISSION_REQUIRED_FROM_PHARMA_COMPANY_MANDATORY",
  ORIOLA_IS_WAITING_FURTHER_INSTRUCTIONS_ERROR:
    "ORIOLA_IS_WAITING_FURTHER_INSTRUCTIONS_ERROR",
  PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_MANDATORY:
    "PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_MANDATORY",
  PHOTOGRAPH_INSTRUCTIONS_MANDATORY: "PHOTOGRAPH_INSTRUCTIONS_MANDATORY",
  MESSAGE_RECIPIENT_MANDATORY: "MESSAGE_RECIPIENT_MANDATORY",
  ATTACHMENT_INVALID_FORMAT: "ATTACHMENT_INVALID_FORMAT",
  ATTACHMENT_TOO_BIG_FILE: "ATTACHMENT_TOO_BIG_FILE",
  ATTACHMENT_EMPTY_FILE: "ATTACHMENT_EMPTY_FILE",
  ATTACHMENT_TOO_MANY_FILES_AT_ONCE: "ATTACHMENT_TOO_MANY_FILES_AT_ONCE",
  ATTACHMENT_OVER_LIMIT: "ATTACHMENT_OVER_LIMIT",
  TRACKING_CODE: "TRACKING_CODE",
  PRODUCT_RETURN_NOT_ALLOWED: "PRODUCT_RETURN_NOT_ALLOWED",
  PRODUCT_MUST_BE_RETURNED: "PRODUCT_MUST_BE_RETURNED",
});

export const CASE_DESCRIPTION_MAX_LENGTH = 1024;
export const ORDER_NUMBER_MAX_LENGTH = 20;
export const RETURN_NUMBER_MAX_LENGTH = 99;
export const RETURN_NUMBER_MIN_LENGTH = 1;
export const HANDLING_INFORMATION_MAX_LENGTH = 1600;
export const IDENTIFIER_CODE_MIN_LENGTH = 3;
export const IDENTIFIER_CODE_MAX_LENGTH = 8;
export const COMPENSATION_NUMBER_MIN_LENGTH = 8;
export const COMPENSATION_NUMBER_MAX_LENGTH = 10;
export const HANDLER_MIN_LENGTH = 2;
export const HANDLER_MAX_LENGTH = 4;
export const REJECTION_DESCRIPTION_MAX_LENGTH = 1024;
export const MESSAGE_MAX_LENGTH = 1600;
export const DETAILED_DEFECT_MAX_LENGTH = 1700;
export const PRODUCT_AMOUNT_RECEIVE_MIN_LENGTH = 1;
export const PRODUCT_AMOUNT_RECEIVE_MAX_LENGTH = 99999;
export const TRACKING_CODE_MAX_LENGTH = 50;
export const CONCLUSION_MAX_LENGTH = 1600;
export const MAX_ATTACHMENT_PER_USER_TYPE = 30;
export const MAX_ATTACHMENT_SIZE = 52428800;
export const MAX_ATTACHMENT_AT_ONCE = 30;

export const isDateInFuture = d => moment(d).isAfter();

export const isValidDate = d => moment(d).isValid();

export const validateMandatory = value => {
  if (!value || (typeof value.trim === "function" && value.trim() === "")) {
    return false;
  }
  return true;
};

export const getValidationErrorMessage = (
  errorType,
  t,
  { field, maxLength, validLength } = {}
) => {
  switch (errorType) {
    case FieldErrors.MANDATORY:
    case FieldErrors.CUSTOMER_MANDATORY:
    case FieldErrors.CONTACT_PERSON_MANDATORY:
    case FieldErrors.PHONE_NUMBER_MANDATORY:
    case FieldErrors.REASON_IDENTIFIER_MANDATORY:
    case FieldErrors.CASE_DESCRIPTION_MANDATORY:
    case FieldErrors.RETURN_CONFIRMATION:
    case FieldErrors.PRODUCT_RETURN_REQUIRED_MANDATORY:
    case FieldErrors.PRODUCTS_HAVE_BEEN_RETURNED_MANDATORY:
    case FieldErrors.PRODUCT_SELLABLE_MANDATORY:
    case FieldErrors.COMPENSATION_PERMITTED_MANDATORY:
    case FieldErrors.REJECTION_REASON_MANDATORY:
    case FieldErrors.REJECTION_DESCRIPTION_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.RETURN_PRODUCT_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.MANDATORY_OR_NOT_AVAILABLE:
      return t("fieldIsMandatoryOrNotAvailable", { field });
    case FieldErrors.NO_REQUIRED_ITEM:
      return t("atLeastOneItem");
    case FieldErrors.RETURN_AMOUNT_INCORRECT:
      return t("amountIsIncorrect", {
        maxLength: RETURN_NUMBER_MAX_LENGTH,
        minLength: RETURN_NUMBER_MIN_LENGTH,
      });
    case FieldErrors.OVER_LENGTH:
      return t("valueTooLong", { maxLength });
    case FieldErrors.CASE_DESCRIPTION_OVER_LENGTH:
      return t("valueTooLong", { maxLength: CASE_DESCRIPTION_MAX_LENGTH });
    case FieldErrors.CONCLUSION_OVER_LENGTH:
      return t("valueTooLong", { maxLength: CONCLUSION_MAX_LENGTH });
    case FieldErrors.INVALID_DATE:
      return t("invalidDate");
    case FieldErrors.INVALID_NUMBER:
      return t("invalidNumber");
    case FieldErrors.INVALID_LENGTH:
      return t("invalidLength", { validLength });
    case FieldErrors.INVALID_LENGTH_CODE:
      return t("invalidLengthCode", { validLength });
    case FieldErrors.INVALID_LENGTH_STRING:
      return t("invalidLengthString", { validLength });
    case FieldErrors.PHONE_NUMBER_INVALID:
      return t("invalidPhoneNumber");
    case FieldErrors.HANDLING_INFORMATION_OVER_LENGTH:
      return t("valueTooLong", { maxLength: HANDLING_INFORMATION_MAX_LENGTH });
    case FieldErrors.REJECTION_DESCRIPTION_OVER_LENGTH:
      return t("valueTooLong", { maxLength: REJECTION_DESCRIPTION_MAX_LENGTH });
    case FieldErrors.MESSAGE_OVER_LENGTH:
      return t("valueTooLong", { maxLength: MESSAGE_MAX_LENGTH });
    case FieldErrors.TRACKING_CODE:
      return t("valueTooLong", { maxLength: TRACKING_CODE_MAX_LENGTH });
    case FieldErrors.MESSAGE_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.QUEUE_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.INVALID_RETURN_RECEIVE_AMOUNT:
      return t("invalidReturnReceiveAmount");
    case FieldErrors.INVALID_UNIQUE_SERIAL_NUMBER:
      return t("invalidUniqueSerialNumber");
    case FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RECIPIENT_CONFIRMATION_MISSING:
      return t("fieldIsMandatory");
    case FieldErrors.CERTIFICATION_OF_DISPOSAL_VALUE_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.CUSTOMER_COMPENSATION_REJECTED_REASON_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.CUSTOMER_COMPENSATION_IS_COMPENSATED_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.CUSTOMER_COMPENSATION_IS_COMPENSATED_DETAILS_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.PERMISSION_REQUIRED_FROM_PHARMA_COMPANY_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.ORIOLA_IS_WAITING_FURTHER_INSTRUCTIONS_ERROR:
      return t("fieldIsMandatory");
    case FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.PHOTOGRAPH_INSTRUCTIONS_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.MESSAGE_RECIPIENT_MANDATORY:
      return t("fieldIsMandatory");
    case FieldErrors.PRODUCT_RETURN_NOT_ALLOWED: {
      return t("productReturnIsNotAllowed");
    }
    case FieldErrors.PRODUCT_MUST_BE_RETURNED: {
      return t("productMustBeReturned");
    }
    case FieldErrors.ATTACHMENT_INVALID_FORMAT: {
      return t("invalidFileFormat");
    }
    case FieldErrors.ATTACHMENT_TOO_BIG_FILE: {
      return t("tooBigFile");
    }
    case FieldErrors.ATTACHMENT_EMPTY_FILE: {
      return t("emptyFile");
    }
    case FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE: {
      return t("invalidFileNumbers");
    }
    case FieldErrors.ATTACHMENT_OVER_LIMIT: {
      return t("invalidFileLimit");
    }
    default:
      return "";
  }
};

export const validatePhoneNumber = value => {
  if (value) {
    if (value.match(/^[+]?[0-9 ()-]*$/)) {
      return true;
    }
    return false;
  }
  return true;
};

export const isInvalidFileNumbers = (files, t) =>
  files.length <= MAX_ATTACHMENT_AT_ONCE
    ? null
    : {
        type: FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE,
        message: t
          ? getValidationErrorMessage(
              FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE,
              t
            )
          : undefined,
      };

export const isInvalidFileFormat = (fileType, fileName, t) => {
  // Get extension from file name
  const splittedFilename = fileName.split(".");

  if (
    !ALLOWED_EXTENSIONS_LIST.includes(
      splittedFilename.pop().toLocaleLowerCase()
    ) ||
    !Object.keys(MIME_TYPES).includes(fileType) ||
    splittedFilename.length < 1
  ) {
    return {
      type: FieldErrors.ATTACHMENT_INVALID_FORMAT,
      message: t
        ? `${getValidationErrorMessage(
            FieldErrors.ATTACHMENT_INVALID_FORMAT,
            t
          )} ${t("supportedFileFormats")} are ${ALLOWED_EXTENSIONS_LIST.join(
            ", "
          )}`
        : undefined,
    };
  }
  return null;
};

const getTooBigFileError = t => ({
  type: FieldErrors.ATTACHMENT_TOO_BIG_FILE,
  message: t
    ? getValidationErrorMessage(FieldErrors.ATTACHMENT_TOO_BIG_FILE, t)
    : undefined,
});

const getEmptyFileError = t => ({
  type: FieldErrors.ATTACHMENT_EMPTY_FILE,
  message: t
    ? getValidationErrorMessage(FieldErrors.ATTACHMENT_EMPTY_FILE, t)
    : undefined,
});

export const isInvalidFileSize = (fileSize, t) => {
  if (fileSize >= MAX_ATTACHMENT_SIZE) {
    return getTooBigFileError(t);
  }
  if (!fileSize) {
    return getEmptyFileError(t);
  }
  return null;
};

export const validateAttachment = (file, t) => {
  let attachmentValidationError = null;
  if (file) {
    const fileFormatError = isInvalidFileFormat(file.type, file.name, t);
    const fileSizeError = isInvalidFileSize(file.size, t);
    if (fileFormatError) {
      attachmentValidationError = fileFormatError;
    }
    if (fileSizeError) {
      attachmentValidationError = fileSizeError;
    }
  }
  return attachmentValidationError;
};

export const validateAttachments = attachments =>
  attachments.reduce((acc, attachment) => {
    const validationError = validateAttachment(attachment.file);
    if (validationError) {
      acc.push(validationError);
    }
    return acc;
  }, []);

export const getAttachmentListNotYetUploaded = attachments =>
  attachments.filter(attachment => attachment.file);

export const validateCompensation = compensation => {
  const { compensationPermitted, compensationRejectionReason } = compensation;
  if (compensationPermitted === false) {
    if (compensationRejectionReason) {
      return true;
    }
    return false;
  }
  return true;
};

export const validateDraftReclamationCase = (reclamationCase, contactInfo) => {
  const errorState = {};
  if (
    !validatePhoneNumber(
      contactInfo.phoneNumber.id
        ? contactInfo.phoneNumber.number
        : contactInfo.phoneNumber
    )
  ) {
    errorState.phoneNumberError = FieldErrors.PHONE_NUMBER_INVALID;
  }
  // customer is mandatory even when user is saving a draft
  if (!validateMandatory(contactInfo.customer)) {
    errorState.customerError = FieldErrors.CUSTOMER_MANDATORY;
  }

  // Validate attachment formats
  if (reclamationCase.attachments?.length) {
    const singleFileValidationError = validateAttachments(
      reclamationCase.attachments
    );
    if (singleFileValidationError.length) {
      errorState.attachmentError = FieldErrors[singleFileValidationError.type];
    }
    const fileNumberError = isInvalidFileNumbers(
      getAttachmentListNotYetUploaded(reclamationCase.attachments)
    );
    if (fileNumberError) {
      errorState.attachmentError =
        FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE;
    }
  }
  return errorState;
};

export const validateContactInfo = (contactInfo, errorState) => {
  if (!validateMandatory(contactInfo.customer)) {
    errorState.customerError = FieldErrors.CUSTOMER_MANDATORY;
  }

  if (!validateMandatory(contactInfo.contactPerson)) {
    errorState.contactPersonError = FieldErrors.CONTACT_PERSON_MANDATORY;
  }

  if (
    !validateMandatory(
      typeof contactInfo.phoneNumber === "object"
        ? contactInfo.phoneNumber.number
        : contactInfo.phoneNumber
    )
  ) {
    errorState.phoneNumberError = FieldErrors.PHONE_NUMBER_MANDATORY;
  }

  if (
    !validatePhoneNumber(
      typeof contactInfo.phoneNumber === "object"
        ? contactInfo.phoneNumber.number
        : contactInfo.phoneNumber
    )
  ) {
    errorState.phoneNumberError = FieldErrors.PHONE_NUMBER_INVALID;
  }
};

export const validateReturnProduct = value => {
  if (value) {
    const { returnValue } = value;
    return !!(returnValue === true || returnValue === false);
  }
  return false;
};

export const validateConfigureReturnNumber = value => {
  if (value) {
    const { returnValue, amount } = value;
    if (returnValue === true) {
      return !!(
        amount >= RETURN_NUMBER_MIN_LENGTH && amount <= RETURN_NUMBER_MAX_LENGTH
      );
    }
    return true;
  }
  return false;
};

export const validateReturnNumber = (
  value,
  category,
  caseStatus,
  caseConfig
) => {
  if (category === CaseType.PRODUCT_DELIVERY) {
    if (caseConfig && caseConfig.packageAmount) {
      return validateConfigureReturnNumber(value);
    }
    return true;
  }
  return validateConfigureReturnNumber(value);
};

export const validateCourierCompanyId = value =>
  Object.values(CourierIds).includes(value);

export const validateReturnConfirm = (returnValue, confirm) => {
  if (returnValue !== null) {
    return !!confirm;
  }
  return true;
};

export const validateIsCustomerCompensated = (
  reclamationCase,
  caseConfig = {},
  errorState = {}
) => {
  if (
    caseConfig.isCustomerCompensated &&
    reclamationCase.detectedBy &&
    reclamationCase.detectedBy.code === "CONSUMER"
  ) {
    const { isCustomerCompensated } = reclamationCase;
    const config = caseConfig.isCustomerCompensated;
    if (
      !isCustomerCompensated ||
      isInValidBoolean(config.code, isCustomerCompensated.code)
    ) {
      errorState.isCustomerCompensatedError = FieldErrors.MANDATORY;
      return;
    }
    if (isOverLength(config.description, isCustomerCompensated.description)) {
      errorState.howWasCustomerCompensatedError = FieldErrors.OVER_LENGTH;
    }
  }
};

export const validateCaseDescription = (
  reclamationCase,
  caseConfig = {},
  errorState = {}
) => {
  if (!caseConfig.caseDescription) {
    return;
  }
  // Validate case description
  if (!validateMandatory(reclamationCase.caseDescription)) {
    errorState.caseDescriptionError = FieldErrors.MANDATORY;
  }
  if (reclamationCase.length > caseConfig.caseDescription.maxLength) {
    errorState.caseDescriptionError = FieldErrors.OVER_LENGTH;
  }
};

export const validateEditableFields = ({
  contactInfo,
  reclamationCase,
  errorState,
  caseConfig,
}) => {
  validateContactInfo(contactInfo, errorState);
  if (!validateMandatory(reclamationCase.reasonIdentifier)) {
    errorState.reasonIdentifierError = FieldErrors.REASON_IDENTIFIER_MANDATORY;
  }
  // Validate product orders, and shipping boxes
  const itemsValidationResult = validateAllItems(
    reclamationCase.items,
    caseConfig
  );
  if (Object.keys(itemsValidationResult).length > 0) {
    errorState.itemsError = itemsValidationResult;
  }
  if (!validateReturnProduct(reclamationCase.productReturn)) {
    errorState.returnProductError = FieldErrors.RETURN_PRODUCT_MANDATORY;
  }
  // Validate case description if reasonIdentifer is OTHER_REFUND_REQUEST
  validateCaseDescription(reclamationCase, caseConfig, errorState);
  // validate if the case has at least one product or shipping box
  if (!validateItemCount(reclamationCase.items, caseConfig)) {
    errorState.noRequiredItemError = FieldErrors.NO_REQUIRED_ITEM;
  }

  // validate product returns
  validateProductReturn(reclamationCase, errorState, FieldErrors);

  // Validate attachment formats
  if (reclamationCase.attachments?.length) {
    const singleFileValidationError = validateAttachments(
      reclamationCase.attachments
    );
    if (singleFileValidationError.length) {
      errorState.attachmentError = FieldErrors[singleFileValidationError.type];
    }
    const fileNumberError = isInvalidFileNumbers(
      getAttachmentListNotYetUploaded(reclamationCase.attachments)
    );
    if (fileNumberError) {
      errorState.attachmentError =
        FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE;
    }
  }
};

const validateGender = (suspectedAdverseReaction, erros) => {
  if (!suspectedAdverseReaction.gender) {
    erros.gender = FieldErrors.MANDATORY;
  }
};

const validateSuspectedAdverseReactionDescrption = (
  suspectedAdverseReaction,
  config,
  errors
) => {
  if (!suspectedAdverseReaction.description) {
    errors.description = FieldErrors.MANDATORY;
  } else if (
    isOverLength(config.description, suspectedAdverseReaction.description)
  ) {
    errors.description = FieldErrors.OVER_LENGTH;
  }
};

export const validateAdverseEvent = (
  reclamationCase,
  caseConfig,
  errorState
) => {
  const { suspectedAdverseReaction } = reclamationCase;
  if (!suspectedAdverseReaction) {
    return;
  }
  if (!suspectedAdverseReaction.report) {
    return;
  }
  const { ageGroup, ageYears, ageUnknown } = suspectedAdverseReaction;
  const config = caseConfig.suspectedAdverseReaction;
  if (!config) {
    return;
  }

  const suspectedAdverseReactionError = {};
  validateGender(suspectedAdverseReaction, suspectedAdverseReactionError);
  if ([ageGroup, ageYears, ageUnknown].filter(x => x || x === 0).length !== 1) {
    suspectedAdverseReactionError.age = FieldErrors.MANDATORY;
  }
  validateSuspectedAdverseReactionDescrption(
    suspectedAdverseReaction,
    config,
    suspectedAdverseReactionError
  );
  if (Object.keys(suspectedAdverseReactionError).length > 0) {
    errorState.suspectedAdverseReactionError = suspectedAdverseReactionError;
  }
};

export const validateSPDEditableFields = ({
  contactInfo,
  reclamationCase,
  errorState,
  caseConfig,
}) => {
  validateContactInfo(contactInfo, errorState);
  const itemsValidationResult = validateAllItems(
    reclamationCase.items,
    caseConfig
  );
  if (!validateMandatory(reclamationCase.reasonIdentifier)) {
    errorState.reasonIdentifierError = FieldErrors.REASON_IDENTIFIER_MANDATORY;
  }

  if (Object.keys(itemsValidationResult).length > 0) {
    errorState.itemsError = itemsValidationResult;
  }

  if (
    reclamationCase.isStatementRequired !== true &&
    reclamationCase.isStatementRequired !== false
  ) {
    errorState.isStatementRequiredError = FieldErrors.MANDATORY;
  }

  if (!reclamationCase.detectedBy || !reclamationCase.detectedBy.code) {
    errorState.detectedByError = FieldErrors.MANDATORY;
  }

  if (
    reclamationCase.suspectedInfectiousProduct !== true &&
    reclamationCase.suspectedInfectiousProduct !== false
  ) {
    errorState.suspectedInfectiousProductError = FieldErrors.MANDATORY;
  }
  validateCaseDescription(reclamationCase, caseConfig, errorState);
  // Validate isCustomerCompensated
  validateIsCustomerCompensated(reclamationCase, caseConfig, errorState);
  // Validate suspected adverse event
  validateAdverseEvent(reclamationCase, caseConfig, errorState);
};

export const validateSPDFields = ({
  contactInfo,
  reclamationCase,
  errorState,
  caseConfig,
  userData,
}) => {
  validateSPDEditableFields({
    contactInfo,
    reclamationCase,
    errorState,
    caseConfig,
  });

  // if oriola creates case for itself these fields are not needed
  const caseIsForOriolaByOriola = isCaseForOriolaByOriola(
    (contactInfo.customer || {}).customerId,
    userData
  );
  if (caseIsForOriolaByOriola === false) {
    // certify infectious product
    if (
      reclamationCase.suspectedInfectiousProduct === false &&
      !validateMandatory(reclamationCase.certify)
    ) {
      errorState.certifyError = FieldErrors.MANDATORY;
    }

    // Do not validate courierCompanyId of a copied reclamation case
    if (
      !reclamationCase.sourceReclamationId &&
      !validateCourierCompanyId(reclamationCase.courierCompanyId)
    ) {
      errorState.courierCompanyError = FieldErrors.MANDATORY;
    }

    // Validate confirmation checkbox
    if (!validateMandatory(reclamationCase.confirm)) {
      errorState.returnConfirmError = FieldErrors.MANDATORY;
    }

    // validate product return
    if (
      !validateReturnNumber(
        reclamationCase.productReturn,
        reclamationCase.caseType,
        reclamationCase.caseStatus,
        caseConfig
      )
    ) {
      errorState.returnNumberError = FieldErrors.RETURN_AMOUNT_INCORRECT;
    }
    // Validate attachments when new SPD case is created
    if (reclamationCase.attachments?.length) {
      const singleFileValidationError = validateAttachments(
        reclamationCase.attachments
      );
      if (singleFileValidationError.length) {
        errorState.attachmentError =
          FieldErrors[singleFileValidationError.type];
      }
      const fileNumberError = isInvalidFileNumbers(
        getAttachmentListNotYetUploaded(reclamationCase.attachments)
      );
      if (fileNumberError) {
        errorState.attachmentError =
          FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE;
      }
    }
  }
};

export const getUploadedFilesInMessagesPerUserType = (
  userType,
  messages = []
) => {
  const uploadedFilesFromMessage = messages.reduce((acc, message) => {
    if (message.creator.type === userType && message.attachments) {
      acc.push(...message.attachments);
    }
    return acc;
  }, []);

  return uploadedFilesFromMessage;
};

export const isInvalidFileLimitPerUserType = (
  userData,
  reclamCase,
  messages,
  toBeUploadedAttachments,
  t
) => {
  const currentUser = getUserType(userData);
  if (currentUser === UserType.PRINCIPAL) {
    let uploadedFilesConclusion =
      reclamCase.items[0].handlingData?.conclusion?.attachments || [];
    let uploadedFilesHandlingInstructions =
      reclamCase.items[0].handlingData?.productHandlingInstructions
        ?.attachments || [];

    uploadedFilesConclusion = uploadedFilesConclusion.filter(
      attachment => !attachment.deleted
    );
    uploadedFilesHandlingInstructions =
      uploadedFilesHandlingInstructions.filter(
        attachment => !attachment.deleted
      );

    const uploadedFilesFromMessage = getUploadedFilesInMessagesPerUserType(
      UserType.PRINCIPAL,
      messages
    );

    const totalFilesBeforeNewUpload =
      uploadedFilesConclusion.length +
      uploadedFilesHandlingInstructions.length +
      uploadedFilesFromMessage.length +
      toBeUploadedAttachments.length;

    return totalFilesBeforeNewUpload <= MAX_ATTACHMENT_PER_USER_TYPE
      ? null
      : {
          type: FieldErrors.ATTACHMENT_OVER_LIMIT,
          message: t
            ? getValidationErrorMessage(FieldErrors.ATTACHMENT_OVER_LIMIT, t)
            : undefined,
        };
  }
  if (currentUser === UserType.CUSTOMER) {
    const initiallyUploadedFiles = reclamCase.caseNumber
      ? reclamCase.attachments || []
      : [];
    const uploadedFilesFromMessage = getUploadedFilesInMessagesPerUserType(
      UserType.CUSTOMER,
      messages
    );

    const totalFilesBeforeNewUpload =
      initiallyUploadedFiles.length +
      uploadedFilesFromMessage.length +
      toBeUploadedAttachments.length;

    return totalFilesBeforeNewUpload <= MAX_ATTACHMENT_PER_USER_TYPE
      ? null
      : {
          type: FieldErrors.ATTACHMENT_OVER_LIMIT,
          message: t
            ? getValidationErrorMessage(FieldErrors.ATTACHMENT_OVER_LIMIT, t)
            : undefined,
        };
  }
  if (currentUser === UserType.ORIOLA) {
    const uploadedFilesFromMessage = getUploadedFilesInMessagesPerUserType(
      UserType.ORIOLA,
      messages
    );

    const totalFilesBeforeNewUpload =
      uploadedFilesFromMessage.length + toBeUploadedAttachments.length;

    return totalFilesBeforeNewUpload <= MAX_ATTACHMENT_PER_USER_TYPE
      ? null
      : {
          type: FieldErrors.ATTACHMENT_OVER_LIMIT,
          message: t
            ? getValidationErrorMessage(FieldErrors.ATTACHMENT_OVER_LIMIT, t)
            : undefined,
        };
  }
  return null;
};

export const validateRejectReason = compensation => {
  const {
    compensationPermitted,
    compensationRejectionDescription,
    compensationRejectionReason,
  } = compensation;
  if (compensationPermitted === false) {
    if (compensationRejectionReason === "other") {
      return !!compensationRejectionDescription;
    }
    return true;
  }
  return true;
};

export const validatePermittedCompensation = compensations => {
  const errors = {};

  compensations.forEach(compensation => {
    const uuid = compensation.id;
    errors[uuid] = [];
    const { compensationNumber, compensationDate, handler, identifierCode } =
      compensation;
    if (!moment(compensationDate).isValid()) {
      errors[uuid].push(CompensationErrors.COMPENSATION_DATE_ERROR);
    }
    if (
      !compensationNumber ||
      compensationNumber.split("").length > COMPENSATION_NUMBER_MAX_LENGTH ||
      compensationNumber.split("").length < COMPENSATION_NUMBER_MIN_LENGTH ||
      Number.isNaN(Number(compensationNumber)) === true
    ) {
      errors[uuid].push(CompensationErrors.COMPENSATION_NUMBER_ERROR);
    }
    if (
      !handler ||
      handler.split("").length > HANDLER_MAX_LENGTH ||
      handler.split("").length < HANDLER_MIN_LENGTH
    ) {
      errors[uuid].push(CompensationErrors.HANDLER_ERROR);
    }
    if (identifierCode.length > 0) {
      if (
        identifierCode.split("").length > IDENTIFIER_CODE_MAX_LENGTH ||
        identifierCode.split("").length < IDENTIFIER_CODE_MIN_LENGTH
      ) {
        errors[uuid].push(CompensationErrors.IDENTIFIER_CODE_ERROR);
      }
    }

    if (errors[uuid].length === 0) {
      delete errors[uuid];
    }
  });

  if (Object.keys(errors).length > 0) {
    return {
      result: false,
      errors,
    };
  }
  return {
    result: true,
  };
};
